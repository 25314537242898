"use client";
import React, { useCallback, useState } from "react";
import Modal from "../Modal";
import useRegisterModal from "@/hooks/useRegisterModal";
import useLoginModal from "@/hooks/useLoginModal";
import RegisterFooter from "./RegisterFooter";
import RegisterBody from "./RegisterBody";

interface Props {
  validatedEmail?: string;
}

const RegisterModal = ({ validatedEmail }: Props) => {
  const registerModal = useRegisterModal();
  const loginModal = useLoginModal();
  const [isLoading, setIsLoading] = useState(false);

  const onToggle = useCallback(() => {
    registerModal.onClose();
    loginModal.onOpen();
  }, [loginModal, registerModal]);

  return (
    <>
      <Modal
        disabled={isLoading}
        isOpen={registerModal.isOpen}
        title="Registrate"
        onClose={registerModal.onClose}
        body={<RegisterBody validatedEmail={validatedEmail} />}
        footer={<RegisterFooter onClick={onToggle} />}
      />
    </>
  );
};

export default RegisterModal;
