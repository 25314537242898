"use client";
import React, { useEffect, useState } from "react";
import FormsHeading from "../FormsHeading";
import Input from "../../Inputs/Input";
import LinksNavigation from "@/constants/navigation";
import ModalsButton from "@/components/Buttons/ModalsButton";
import { signIn } from "next-auth/react";
import { addDaysToString } from "@/commons/helpers/dateUtils";
import useAlertModal from "@/hooks/useAlertModal";
import AlertModal from "../createVehicle/AlertModal";
import { usePathname, useSearchParams } from "next/navigation";
import useBookingDates from "@/hooks/useBookingDates";

interface Props {
  validatedEmail?: string;
}

function LoginBody({ validatedEmail }: Props) {
  const alertModal = useAlertModal();
  const links = LinksNavigation();
  const [emailInput, setEmailInput] = useState(validatedEmail ?? "");
  const [passwordInput, setPasswordInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const { location, startDate, endDate } = useBookingDates();

  // Redirect URL after successful login.
  const [redirectUrl, setRedirectUrl] = useState(
    `/cars/search?location=${location}&period=${addDaysToString(
      startDate,
      0
    )},${addDaysToString(endDate, 0)}`
  );

  useEffect(() => {
    if (searchParams) {
      const params = new URLSearchParams(searchParams);
      if (params.has("error")) {
        alertModal.onOpen();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (
      pathname &&
      pathname !== "/" &&
      pathname !== "/login" &&
      pathname !== "/register"
    ) {
      if (searchParams) {
        const params = new URLSearchParams(searchParams);
        setRedirectUrl(`${pathname}?${params.toString()}`);
      } else {
        setRedirectUrl(pathname);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  // Function to handle form submission.
  const onSubmit = async () => {
    setIsLoading(true);
    //Next Auth signIn function
    const result = await signIn("credentials", {
      email: emailInput,
      password: passwordInput,
      callbackUrl: redirectUrl,
      redirect: true,
    });
    setIsLoading(false);
  };

  return (
    <>
      <AlertModal
        title={"Credenciales Inválidas"}
        subtitle={
          "! Ops! Algo salió mal. Revisa si tus credenciales son correctas. Si el error persiste, comunícate con el servicio al cliente."
        }
      />

      <div className="flex flex-col gap-4">
        {/* Render the heading for the login form */}
        <FormsHeading
          title="Bienvenido a ATUA"
          subtitle="¡Entrá a tu cuenta!"
        />

        {/* Render the email input */}
        <Input
          id="email"
          label="Email"
          disabled={isLoading}
          required
          value={emailInput}
          onChange={(e) => setEmailInput(e.target.value)}
        />

        {/* Render the password input */}
        <Input
          id="password"
          label="Password"
          type="password"
          disabled={isLoading}
          required
          value={passwordInput}
          onChange={(e) => setPasswordInput(e.target.value)}
        />

        {/* Render the "Forgot Password" link */}
        <p className="text-right">
          <span
            onClick={links.handleClickForgotPassword}
            className="text-sm text-violet-700 cursor-pointer hover:underline"
          >
            Olvidaste tu contraseña
          </span>
        </p>

        {/* Render the login button */}
        <div className="pt-5">
          <ModalsButton
            label="Inicia Sesión"
            onClick={onSubmit}
            disabled={isLoading}
            disabledText="Iniciando sesión..."
          />
        </div>
      </div>
    </>
  );
}

export default LoginBody;
