import React from "react";

interface Props {
  onClick?: () => void;
}

function LoginFooter({ onClick }: Props) {
  return (
    <div className="flex flex-col gap-4 mt-3">
      <div className="text-neutral-500 text-center mt-4 font-light text-sm">
        <p>
          ¿Primera vez en ATUA?
          <span
            onClick={onClick}
            className=" text-violet-700 cursor-pointer hover:underline"
          >
            {" "}
            Registrate
          </span>
        </p>
      </div>
    </div>
  );
}

export default LoginFooter;
